import { Grid } from '@mui/material';
import { Product } from '../../app/models/product';
import ProductCard from './ProductCard';
import { useAppSelector } from '../../app/store/configureStore';
import ProductCardSkeleton from './ProductCardSkeleton';

interface Props {
    products: Product[];
}

const ProductList = ({products}: Props) => {
    const {productsLoaded} = useAppSelector(state => state.catalog)

    return (
        
            <Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {products.map(product => (
                    // <Grid item xs={3} key={product.id} sx={{mb: 2}}>
                    //     <ProductCard product={product} />
                    // </Grid>
                    
                    
                      <Grid item xs={12} sm={4} md={4} sx={{mb: 2}} key={product.id}>
                        {!productsLoaded ? (
                            <ProductCardSkeleton />
                        ): (
                            <ProductCard product={product} />
                        )}
                      </Grid>
                    
                
                    
                ))}
            </Grid>
        
    );
};

export default ProductList;