import { Avatar, Box, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import { Call, ContactMail, LocalPostOfficeRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import React from 'react';
import { toast } from 'react-toastify';

const ContactPage = () => {
    const { register, formState: { isSubmitting, errors, isValid } } = useForm({
        mode: 'onTouched'
    });


    const [result, setResult] = React.useState("");

    const onSubmit = async (event:any) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "e24a594a-d77c-4a60-be9e-210999e23ce0");
        // formData.append("access_key", "cb2e8e9c-24c9-43a4-aeb8-59b27c15ad8d");

        const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
        });

        const data = await response.json();

        if (data.success) {
        setResult("Request Submitted");
        toast.success("Form Submitted Successfully");
        event.target.reset();
        } else {
        // console.log("Error", data);
        setResult(data.message);
        toast.error(data.message);
        }
    };

    return (
    <Box sx={{ my: 2, flexGrow: 4 }}>
        <Grid container columnSpacing={4}>
        <Grid item xs={12} md={6}>
            <Container component={Paper} maxWidth='sm' sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems:'center'}}>
            <img src='BannerLight.png' alt="Slogan AccuFinTech" width='350px' height='110px' style={{marginRight:'19px'}}/>
                <Typography component="h3" variant="h6">
                
                    <br />
                    If you have any quick questions, feel free to contact us:
                    <br />
                    <br />
                    <Call sx={{mb:-0.8}}/>Tel: (813) 336-4993,
                    <br />
                    <br />
                    <LocalPostOfficeRounded sx={{mb:-0.8}} /> 8539 Manassas Rd, Tampa, FL, 33635, USA
                </Typography>
            </Container>
        </Grid>

        <Grid item xs={12} md={6} sx={{mb:2}}> 
        <Container component={Paper} maxWidth='sm' sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          
            
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <ContactMail />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Stay with us
                </Typography>
                <Box component="form"
                    onSubmit={onSubmit}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        autoFocus
                        label="Full Name"
                        {...register('fullName', { required: 'Full Name is required' })}
                        error={!!errors.fullName}
                        helperText={errors?.fullName?.message as string}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Contact number with country code"
                        {...register('contactNum', { required: 'Contact number is required' })}
                        error={!!errors.contactNum}
                        helperText={errors?.contactNum?.message as string}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Email (Please insert the valid email address, as quick reply)"
                        {...register('email', { 
                            required: 'Email is required',
                            pattern: {
                                value: /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
                                message: 'Not a valid email address'
                            }
                        })}
                        error={!!errors.email}
                        helperText={errors?.email?.message as string}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        label="Messages (Don't share any misusable information (Like: Credit card)"
                        {...register('messages', { required: 'Messages is required' })}
                        error={!!errors.messages}
                        helperText={errors?.messages?.message as string}
                        rows='7'
                        maxRows='6'
                    />
                    <LoadingButton
                        loading={isSubmitting}
                        disabled={!isValid}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send
                    </LoadingButton>
                    <span>{result}</span>
                                {/* <Grid container>
                                    <Grid item>
                                        <Link to='/login' style={{ textDecoration: 'none' }}>
                                            {"Already have an account? Sign In"}
                                        </Link>
                                    </Grid>
                                </Grid> */}
                </Box>
            
        </Container>
        </Grid>
            </Grid>
        </Box>
    );
};

export default ContactPage;