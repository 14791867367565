import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import { Badge, Divider, Drawer, FormControlLabel, FormGroup, IconButton, List, ListItem, styled, Zoom, Typography, Collapse, ListItemText } from '@mui/material';
import { Link, NavLink } from 'react-router-dom';
import { ExpandLess, ExpandMore, ShoppingCart } from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import MenuIcon from '@mui/icons-material/Menu';
import SignedInMenu from './SignedInMenu';
import { signOut } from '../../features/account/accountSlice';
import { clearBasket } from '../../features/basket/basketSlice';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#aab4be',
          ...theme.applyStyles('dark', {
            backgroundColor: '#8796A5',
          }),
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#001e3c',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
      ...theme.applyStyles('dark', {
        backgroundColor: '#121212',
      }),
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#fff',
      borderRadius: 20 / 2,
      ...theme.applyStyles('dark', {
        backgroundColor: '#8796A5',
      }),
    },
  }));

const midLinks = [
    {title:'home', path:'/'},
    {title:'catalog', path:'/catalog'},
    {title:'about', path:'/about'},
    {title:'contact', path:'/contact'},
]

const midLinksMobile = [
  {title:'catalog', path:'/catalog'},
  {title:'about', path:'/about'},
  {title:'contact', path:'/contact'},
]

const rightLinks = [
    {title:'login', path:'/login'},
    // {title:'register', path:'/register'},
]

const navStyles = {
    color: 'inherit', 
    textDecoration:'none',
    typography:'h6',
    '&:hover': {
        color:'grey.400'
    },
    '&.active':{
        color:'grey.500'
    }
}

const navSubStyles = {
  color: 'inherit', 
  textDecoration:'none',
  'fontSize': 18,
  'ml': 2,
  '&:hover': {
      color:'grey.400'
  },
  '&.active':{
      color:'grey.500'
  }
}

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: React.ReactElement<unknown>;
  darkMode: boolean;
  handleThemeChange: () => void;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children ?? <div />}
    </Slide>
  );
}

const drawerWidth = 240;


const Header = ({darkMode, handleThemeChange}: Props, props: Props) => {
  const {basket} = useAppSelector(state => state.basket);
  const {user} = useAppSelector(state => state.account);
  const itemCount = basket?.items.reduce((sum, item) => sum + item.quantity, 0)

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [mobListOpen, setmobListOpen] = React.useState(false);

  const handleMobClick = () => {
    setmobListOpen(!mobListOpen);
    setMobileOpen((prevState) => !prevState);
  };

  const dispatch = useAppDispatch();

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'start' }}>
      <List>
        <ListItem
          component={NavLink}
          to='/'
          key='/'
          sx={navStyles}
        >
          HOME
        </ListItem>
      </List>
      <Divider />
      <List>
        {midLinksMobile.map(({title, path}) =>(
            <ListItem
                component={NavLink}
                to={path}
                key={path}
                sx={navStyles}
            >
                {title.toUpperCase()}
            </ListItem>
        ))}
      </List>
      <Divider />
      
        {user ? (
              <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                
              >
                <ListItem onClick={handleMobClick}>
                  <ListItemText 
                      primaryTypographyProps={{
                        fontSize: 20,
                        fontWeight: 'medium',
                        letterSpacing: 0,
                      }}
                    >
                  {user?.nameID.toUpperCase()}
                  </ListItemText>
                  {/* <ListItemText primary={user?.nameID.toUpperCase()} /> */}
                    {mobListOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  <Collapse in={mobListOpen} timeout="auto">
                    <List component="div" disablePadding>
                      <ListItem
                        component={NavLink}
                        to={'/profiles'}
                        sx={navSubStyles}
                      >
                        Profile
                    </ListItem>
                    <ListItem
                        component={NavLink}
                        to={'/orders'}
                        sx={navSubStyles}
                      >
                        My orders
                    </ListItem>
                    
                    {user && user.roles?.includes('Admin') &&
                        <ListItem
                                component={NavLink}
                                to={'/inventory'}
                                sx={navSubStyles}
                            >
                                Inventory
                            </ListItem>
                          }
                    <ListItem
                        component={NavLink}                    
                        sx={navSubStyles}
                        to={''}
                        onClick={() => {
                          dispatch(signOut());
                          dispatch(clearBasket());
                        }}
                      >
                        Logout
                    </ListItem>
                    </List>
                </Collapse>
                

              
            </List>
            ): (
              <List sx={{display:'flex'}}>
                {rightLinks.map(({title, path}) =>(
                    <ListItem
                        component={NavLink}
                        to={path}
                        key={path}
                        sx={navStyles}
                    >
                        {title.toUpperCase()}
                    </ListItem>
                ))}
              </List>
            )}
      
      <Divider />
      <List>
        <IconButton component={Link} to='/basket' size='large' edge='start' color='inherit' sx={{ml: 1}}>
            <Badge badgeContent={itemCount} color='secondary'>
                <ShoppingCart/>
            </Badge>
        </IconButton>
      </List>
    </Box>
  );
  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;


  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar sx={{bgcolor:'grey.800'}}>
            <Toolbar sx={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          
                  <Box display={{ xs: 'flex', sm: 'flex' }} alignItems='center'>
                    <Zoom in={true} style={{ transitionDelay: '1000ms' }}>
                    <img src="/LogoLight.png" alt="Logo AccuFinTech" width='50px' height='50px' style={{margin:'5px'}}/>
                    </Zoom>  
                   
                    <Zoom in={true} style={{ transitionDelay: '1200ms' }}>
                    <Typography component={NavLink}
                    to='/'
                    sx={{color: 'inherit', 
                      textDecoration:'none',
                      typography:'h6',}}
                    >
                       {/* <Zoom in={true} style={{ transitionDelay: '500ms' }}><img src="slogan.png" alt="Logo AccuFinTech" width='160px' height='40px' style={{marginTop:'10px'}}/></Zoom> */}
                      
                      {/* <img src={!darkMode ? 'BannerLight.png' : 'BannerDark.png'} alt="banner AccuFinTech" width='210px' height='70px' /> */}
                      
                       <span>A</span><span style={{fontSize:15, letterSpacing:'6'}}>CCU</span>F<span></span><span style={{fontSize:15, letterSpacing:'6'}}>IN</span>T<span style={{fontSize:15, letterSpacing:'6'}}>ECH</span>  
                      
                    </Typography>
                    </Zoom>

                    <Zoom in={true} style={{ transitionDelay: '1400ms' }}>
                      <FormGroup sx={{ display: { xs: 'flex', sm: 'flex' } }}>
                          <FormControlLabel
                              control={<MaterialUISwitch sx={{ ml: 3 }} checked={darkMode} onChange={handleThemeChange} />}
                              label= {darkMode? '' : ''}
                          />
                      </FormGroup>
                    </Zoom>
                  </Box>
                    {/* <Switch checked={darkMode} onChange={handleThemeChange} /> */}
                
                
                <List sx={{ display: { xs: 'none', sm: 'none', md:'flex' } }}>
                    {midLinks.map(({title, path}) =>(
                        <ListItem
                            component={NavLink}
                            to={path}
                            key={path}
                            sx={navStyles}
                        >
                            {title.toUpperCase()}
                        </ListItem>
                    ))}
                    {user && user.roles?.includes('Admin') &&
                      <ListItem
                              component={NavLink}
                              to={'/inventory'}
                              sx={navStyles}
                          >
                              INVENTORY
                          </ListItem>
                    }
                </List>


                <Box display='flex' alignItems='center' sx={{ display: { xs: 'flex', sm: 'flex', md:'none' } }}>
                    <IconButton component={Link} to='/basket' size='large' edge='start' color='inherit' sx={{mr: 2}}>
                        <Badge badgeContent={itemCount} color='secondary'>
                            <ShoppingCart/>
                        </Badge>

                    </IconButton>
                </Box>
                <Box display='flex' alignItems='center' sx={{ display: { xs: 'none', sm: 'none', md:'flex' } }}>
                    <IconButton component={Link} to='/basket' size='large' edge='start' color='inherit' sx={{mr: 2}}>
                        <Badge badgeContent={itemCount} color='secondary'>
                            <ShoppingCart/>
                        </Badge>

                    </IconButton>

                    {user ? (
                      <SignedInMenu />
                    ): (
                      <List sx={{display:'flex'}}>
                        {rightLinks.map(({title, path}) =>(
                            <ListItem
                                component={NavLink}
                                to={path}
                                key={path}
                                sx={navStyles}
                            >
                                {title.toUpperCase()}
                            </ListItem>
                        ))}
                      </List>
                    )}

                </Box>

            </Toolbar>

        </AppBar>
      </HideOnScroll>
      <Toolbar />
      {/* <div style={{textAlign:'center'}}><Box sx={{ mt: 3}}><img src={!darkMode ? 'BannerLight.png' : 'BannerDark.png'} alt="banner AccuFinTech" width='310px' height='100px' /></Box></div> */}
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
     
      {/* <Typography sx={{mt: 0}}><Outlet/></Typography> */}
    </React.Fragment>
  );
}
export default Header;




// import { AppBar, Switch, Toolbar, Typography } from '@mui/material';

//  interface Props {
//     darkMode: boolean;
//     handleThemeChange: () => void;
// }

// const Header = ({darkMode, handleThemeChange}: Props) => {
//     return (
//         <>
//             <AppBar sx={{mb: 4, bgcolor:'grey.800'}}>
//                 <Toolbar>
//                     <Typography variant='h6'>
//                         AccuFinTech
//                     </Typography>
//                     <Switch checked={darkMode} onChange={handleThemeChange} />
//                 </Toolbar>

//             </AppBar>
//         </>
//     );
// };

// export default Header;