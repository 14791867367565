import { CardMembership, EnergySavingsLeaf, MiscellaneousServicesTwoTone } from '@mui/icons-material';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';

const AboutPage = () => {
    // const [validationErrors, setValidationErrors] = useState<string[]>([]);

    // function getValidationError() {
    //     agent.TestErrors.getValidationError()
    //         .then(() => console.log('should not see this!'))
    //         .catch(error => setValidationErrors(error));
    // }

    return (
        <Box sx={{ my: 2, flexGrow: 4 }}>
            <Grid container columnSpacing={4}>
                <Grid item xs={12} md={3} sx={{mt:2}}>
                   
                </Grid>
                <Grid item xs={12} md={6} sx={{mt:2}}>
                    <Container component={Paper} maxWidth='sm' sx={{ height:300, border:2, borderRadius:5, borderColor:'GrayText', p: 4, display: 'flex', textJustify:'auto', alignItems:'center', verticalAlign:''}}>
                    
                        <Typography component="h3" variant="h6" textAlign='center'>
                        
                            <CardMembership /><br />
                            About Team:
                            <br />
                            <br />
                            "We have: Dedication, challenges and high responsiveness with our honorable clients”
                            
                        </Typography>
                    </Container>
                </Grid>

                <Grid item xs={12} md={3} sx={{mt:2}}>
                
                </Grid>
            </Grid>

            <Grid container columnSpacing={4}>
                <Grid item xs={12} md={6} sx={{mt:2}}>
                    <Container component={Paper} maxWidth='sm' sx={{ height:300, border:2, borderRadius:5, borderColor:'GrayText', p: 4, display: 'flex', textJustify:'auto', alignItems:'center', verticalAlign:''}}>
                    
                        <Typography component="h3" variant="h6" textAlign='center'>
                        
                            <MiscellaneousServicesTwoTone /><br />
                            Mission Statements:
                            <br />
                            <br />
                            “Delivering value-driven financial insights and tech solutions that enhance business performance and future readiness.”
                            
                        </Typography>
                    </Container>
                </Grid>

                <Grid item xs={12} md={6} sx={{mt:2}}>
                <Container component={Paper} maxWidth='sm' sx={{ height:300, border:2, borderRadius:5, borderColor:'GrayText', p: 4, display: 'flex', textJustify:'auto', alignItems:'center', verticalAlign:''}}>
                    
                        <Typography component="h3" variant="h6" textAlign='center'>

                            <EnergySavingsLeaf /><br />
                            Vision Statements:
                            <br />
                            <br />
                            “To transform the financial landscape by providing seamless digital solutions that promote efficiency and growth.”
                            
                        </Typography>
                    </Container>
                </Grid>
            </Grid>
        </Box>
        
    )
}

export default AboutPage;

// <Container>
        //     <Typography gutterBottom variant={'h2'}>Errors for testing purposes</Typography>
        //     <ButtonGroup fullWidth>
        //         <Button onClick={() => agent.TestErrors.get500Error().catch(error => console.log(error))} variant={'contained'}>Test 500 error</Button>
        //         <Button onClick={() => agent.TestErrors.get404Error().catch(error => console.log(error))} variant={'contained'}>Test 404 error</Button>
        //         <Button onClick={() => agent.TestErrors.get400Error().catch(error => console.log(error))} variant={'contained'}>Test 400 error</Button>
        //         <Button onClick={getValidationError} variant={'contained'}>Test 400 validation
        //             error</Button>
        //         <Button onClick={() => agent.TestErrors.get401Error().catch(error => console.log(error))} variant={'contained'}>Test 401 error</Button>
        //     </ButtonGroup>
        //     {validationErrors.length > 0 &&
        //         <Alert severity="error">
        //             <AlertTitle>Validation Errors</AlertTitle>
        //             <List>
        //                 {validationErrors.map(error => (
        //                     <ListItem key={error}>
        //                         <ListItemText>{error}</ListItemText>
        //                     </ListItem>
        //                 ))}
        //             </List>
        //         </Alert>}
        // </Container>