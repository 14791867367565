import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Typography } from '@mui/material';
import { Product } from '../../app/models/product';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { currencyFormat } from '../../app/util/util';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import { addBasketItemAsync } from '../basket/basketSlice';

interface Props {
    product: Product;
}

const ProductCard = ({product}: Props) => {
    const {status} = useAppSelector(state => state.basket);
    const dispatch = useAppDispatch();

    return (
        <div>
            <Card sx={{border:0, borderRadius: 5}} elevation={10}>
                <CardHeader
                    avatar={
                        <Avatar sx={{bgcolor:'grey.500'}}>
                            {product.name.charAt(0).toUpperCase()}
                        </Avatar>
                    }
                    title={product.name}
                    titleTypographyProps={{
                        sx: {fontWeight:'bold'}
                    }}
                />
                <CardMedia
                    sx={{ height: 140, backgroundSize:'contain', bgcolor:'grey.100' }}
                    image={product.pictureUrl}
                    title={product.name}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5">
                        {currencyFormat(product.price)}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {product.brand} / {product.type}
                    </Typography>
                </CardContent>
                <CardActions>
                    <LoadingButton 
                        loading={status.includes('pendingAddItem' + product.id)} 
                        onClick={() => dispatch(addBasketItemAsync({productId: product.id}))} 
                        size="small">Add to cart</LoadingButton>
                    <Button component={Link} to={`/catalog/${product.id}`} size="small">View</Button>
                </CardActions>
            </Card>
        </div>
    );
};

export default ProductCard;

// {/* <ListItem key={product.id}>
//     <ListItemAvatar>
//         <Avatar src={product.pictureUrl} />
//     </ListItemAvatar>
//     <ListItemText>
//         {product.name} - {product.price}
//     </ListItemText>
// </ListItem> */}