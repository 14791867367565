import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Product, ProductAllParams } from "../../app/models/product";
import agent from "../../app/api/agent";
import { RootState } from "../../app/store/configureStore";
// import { MetaData } from "../../app/models/pagination";

interface CatalogAllState {
    productsAllLoaded: boolean;
    status: string;
    productAllParams: ProductAllParams;
}

const productsAllAdapter = createEntityAdapter<Product>();

function getAllAxiosParams(productAllParams: ProductAllParams) {
    const allParams = new URLSearchParams();
    allParams.append('pageNumber', productAllParams.pageNumber.toString()) ;
    allParams.append('pageSize', productAllParams.pageSize.toString(10));
    allParams.append('orderBy', productAllParams.orderBy);
    // if (productAllParams.searchTerm) allParams.append('searchTerm', productAllParams.searchTerm);
    // if (productAllParams.types.length > 0) allParams.append('types', productAllParams.types.toString());
    // if (productAllParams.brands.length > 0) allParams.append('brands', productAllParams.brands.toString());
    return allParams;
}

export const fetchAllProductsAsync = createAsyncThunk<Product[], void, {state: RootState}>(
    'catalog/fetchAllProductsAsync',
    async (_, thunkAPI) => {
        const params = getAllAxiosParams(thunkAPI.getState().home.productAllParams);
        try {
            const response = await agent.Catalog.list(params);
            // thunkAPI.dispatch(setMetaData(response.metaData));
            return response.items;
        } catch (error:any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)
//end mothod fetch All Products Async


function initAllParams(): ProductAllParams {
    return {
        pageNumber: 1,
        pageSize: 10,
        orderBy: 'name',
        brands: [],
        types: []
    }
}
//end mothod initParams


//............./ reducers & extraReducers /...............//

export const homeSlice = createSlice({
    name: 'home',
    initialState: productsAllAdapter.getInitialState<CatalogAllState>({            
        productsAllLoaded: false,
        status: 'idle',
        productAllParams: initAllParams(),
    }),
    reducers: {
        setAllProductParams: (state, action) => {
            state.productsAllLoaded = false;
            state.productAllParams = {...state.productAllParams, ...action.payload, pageNumber: 1}
        },
        
    },
    extraReducers: (builder => {
        // fetch All Products Async
        builder.addCase(fetchAllProductsAsync.pending, (state) => {
            state.status = 'pendingFetchAllProducts';
        });
        builder.addCase(fetchAllProductsAsync.fulfilled, (state, action) => {
            productsAllAdapter.setAll(state, action.payload);
            state.status = 'idle';
            state.productsAllLoaded = true;
        });
        builder.addCase(fetchAllProductsAsync.rejected, (state) => {
            // console.log(action.payload);            
            state.status = 'idle';
        });
    })
})
// end mothod

export const productAllSelectors = productsAllAdapter.getSelectors((state: RootState) => state.home);

export const {setAllProductParams} = homeSlice.actions;