import { PrivacyTipRounded } from "@mui/icons-material";
import { Box, Grid, Container, Paper, Typography } from "@mui/material";

const PrivacyPolicy = () => {
    return (
        <Box sx={{ my: 2, flexGrow: 4 }}>
            <Grid columnSpacing={4}>
                <Grid item xs={12} md={3} sx={{mt:2}}>
                   
                </Grid>
                <Grid item xs={12} md={6} sx={{mt:2}}>
                    <Container component={Paper} maxWidth='sm' sx={{border:2, borderRadius:5, borderColor:'GrayText', p: 4, display: 'flex', textJustify:'auto', alignItems:'center', verticalAlign:''}}>
                    
                        <Typography component="h3" variant="h6" textAlign='center'>
                        
                            <PrivacyTipRounded /><br />
                            Privacy Policy:
                            <br />
                            <br />
                            Effective Date: [Insert Date]<br />

AccuFinTech Incorporation (“we,” “our,” or “us”) values your privacy. This policy outlines how we collect, use, and protect your personal information when you engage with our services, websites, or applications.

                            
                        </Typography>
                    </Container>
                </Grid>

                <Grid item xs={12} md={3} sx={{mt:2}}>
                
                </Grid>
            </Grid>

            <Grid sx={{mt:2, width:'100%'}} columnSpacing={0}>
                <Grid item xs={12} >
                    <Container sx={{border:2, borderRadius:5, borderColor:'GrayText', p: 4, display: 'flex', textJustify:'inter-word', alignItems:'center'}}>
                    
                        <Typography component="h6" variant="h6">
                        

<p>
<h3>1. Information We Collect</h3>
    <p>We may collect the following types of information:</p>
    <ul>
        <li><strong>Personal Information:</strong> Name, email, phone number, address, date of birth, and payment information.</li>
        <li><strong>Financial Information:</strong> Bank account details, credit/debit card information, and transaction history when using our services.</li>
        <li><strong>Technical Data:</strong> IP address, browser type, device information, and usage data collected through cookies or analytics tools.</li>
        <li><strong>Third-Party Data:</strong> Information obtained from partners, credit agencies, or public databases to comply with regulatory requirements.</li>
    </ul>

    <h3>2. How We Use Your Information</h3>
    <p>We use your data for purposes such as:</p>
    <ul>
        <li>Providing and improving our services.</li>
        <li>Verifying your identity and processing transactions.</li>
        <li>Complying with legal and regulatory obligations.</li>
        <li>Sending service updates, promotional materials, or critical alerts.</li>
        <li>Conducting market analysis and improving customer experience.</li>
    </ul>
<h3>3. Sharing Your Information</h3>
    <p>We do not sell your data. However, we may share information with:</p>
    <ul>
        <li><strong>Service Providers:</strong> Partners who assist in processing payments, maintaining our systems, or providing analytics.</li>
        <li><strong>Legal Authorities:</strong> When required to comply with legal obligations or to protect our rights.</li>
        <li><strong>Affiliates:</strong> Within our corporate group to deliver integrated services.</li>
    </ul>

    <h3>4. Data Security</h3>
    <p>We implement industry-standard measures to safeguard your data, including:</p>
    <ul>
        <li>Encryption for sensitive data.</li>
        <li>Secure access controls.</li>
        <li>Regular audits and monitoring for vulnerabilities.</li>
    </ul>
    <p>While we strive to protect your data, no system can guarantee complete security.</p>

    <h3>5. Your Rights</h3>
    <p>Depending on your jurisdiction, you may have rights to:</p>
    <ul>
        <li>Access, correct, or delete your data.</li>
        <li>Opt-out of marketing communications.</li>
        <li>Restrict or object to certain data processing activities.</li>
        <li>File a complaint with a regulatory body.</li>
    </ul>
    <p>To exercise your rights, contact us at [Insert Contact Information].</p>

    <h3>6. Cookies and Tracking Technologies</h3>
    <p>We use cookies to:</p>
    <ul>
        <li>Personalize your experience.</li>
        <li>Analyze website traffic.</li>
        <li>Improve service functionality.</li>
    </ul>
    <p>You can manage cookies through your browser settings.</p>

    <h3>7. Children's Privacy</h3>
    <p>Our services are not intended for individuals under 18. We do not knowingly collect data from minors.</p>

    <h3>8. Updates to This Policy</h3>
    <p>We may update this policy periodically. Changes will be posted with an updated “Effective Date.”</p>

    <h3>9. Contact Us</h3>
    <p>For questions or concerns regarding this policy, contact: <br />
    
        <ul>
            <li>
            <b>Accufintech Incorporation</b>
            </li>
            <li>8539 Manassas Rd, Tampa, FL, 33635, USA</li>
            <li>Tel: (813) 336-4993</li>
            <li>[Insert Email Address]</li>
        </ul>
        </p>

</p>
                            
                        </Typography>
                    </Container>
                </Grid>
                
            </Grid>
        </Box>
    );
};

export default PrivacyPolicy;