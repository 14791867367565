import { Divider, Grid, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NotFound from '../../app/errors/NotFound';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import { addBasketItemAsync, removeBasketItemAsync } from '../basket/basketSlice';
import { fetchProductAsync, productSelectors } from './catalogSlice';

const ProductDetails = () => {
    const {basket, status} = useAppSelector(state => state.basket);
    const dispatch = useAppDispatch();
    const {id} = useParams<{id: string}>();
    const product = useAppSelector(state => productSelectors.selectById(state, parseInt(id!)));
    const {status: productStatus} = useAppSelector(state => state.catalog);
    const [quantity, setQuantity] = useState(0);
    const item = basket?.items.find(i => i.productId === product?.id);

    useEffect(() =>{
        if (item) setQuantity(item.quantity);
        if (!product) dispatch(fetchProductAsync(parseInt(id!)))
    }, [id, item, dispatch, product])

    function handleInputChange(event: any) {
        if (event.target.value >= 0){
            setQuantity(parseInt(event.target.value));
        }
    }

    function handleUpdateCart() {
        if (!item || quantity > item.quantity){
            const updatedQuantity = item ? quantity - item.quantity : quantity;
            dispatch(addBasketItemAsync({productId: product?.id!, quantity: updatedQuantity}))
        } else {
            const updatedQuantity = item.quantity - quantity;
            dispatch(removeBasketItemAsync({productId: product?.id!, quantity: updatedQuantity}))
        }
    }

    if (productStatus.includes('pending')) return <LoadingComponent message="Loading..." />

    if (!product) return <NotFound/>

    return (
        <div>
            {/* <Typography variant='h2'>
                {product.name}
            </Typography> */}
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 12, md: 12 }} sx={{ mt: 2 }}>
                 <Grid item xs={2} sm={4} md={4}>
                    <img src={product.pictureUrl} alt={product.name} style={{width:'100%'}} />
                </Grid>
                <Grid item xs={2} sm={8} md={8}>
                    <Typography variant='h2'>
                        {product.name}
                    </Typography>
                    <Divider sx={{mb: 2}} />
                    <Typography variant='h5'>
                        Registration in : ${(product.price / 100).toFixed(2)}
                    </Typography>
                    <Divider sx={{m: 2}} />


                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField onChange={handleInputChange} variant='outlined' type='number' label='Quantity in Cart' fullWidth value={quantity} />
                        </Grid>
                        <Grid item xs={6}>
                            <LoadingButton
                                disabled={(item?.quantity === quantity) || (!item && quantity === 0)}
                                loading={status.includes('pending')}
                                onClick={handleUpdateCart}
                                sx={{height: '55px'}}
                                color='primary'
                                size='large'
                                variant='contained'
                                fullWidth
                            >
                                {item ? 'Update Quantity' : 'Add to cart'}
                            </LoadingButton>
                        </Grid>
                    </Grid>


                    <TableContainer>
                        <Table>
                            <TableBody>
                                {/* <TableRow>
                                    
                                    <TableCell>{product.name}</TableCell>
                                </TableRow> */}
                                <TableRow>
                                    
                                    <TableCell sx={{alignContent: 'baseline'}} dangerouslySetInnerHTML={{__html: `${product.description}`}}>
                                    </TableCell>
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell>Type</TableCell>
                                    <TableCell>{product.type}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Brand</TableCell>
                                    <TableCell>{product.brand}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Quantity in stock</TableCell>
                                    <TableCell>{product.quantityInStock}</TableCell>
                                </TableRow> */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                </Grid>
            </Grid>
        </div>
    );
};

export default ProductDetails;