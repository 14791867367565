import { Box, Pagination } from "@mui/material";
import { MetaData } from "../models/pagination";
import { useState } from "react";

interface Props {
    metaData: MetaData;
    onPageChange: (page: number) => void;
}

const AppPagination = ({metaData, onPageChange}: Props) => {
    const {currentPage, totalPages} = metaData;
    const [pageNumber, setPageNumber] = useState(currentPage);

    function handlePageChange(page: number){
        setPageNumber(page);
        onPageChange(page);
    }

    return (
        <>
            {/* <Box sx={{ display: { xs: 'block', sm: 'flex', md:'flex' }, textAlign: {xs: 'center', sm:'justify'}, justifyContent: {xs: 'center', sm:'space-between'} }} alignItems='center'> */}
            <Box display='flex' justifyContent='center' sx={{p:1, border:1, borderRadius:5}} width={'100%'} >
                {/* <Typography>
                    Displaying {(currentPage-1)*pageSize+1}-
                    {currentPage*pageSize > totalCount 
                        ? totalCount
                        : currentPage*pageSize} of {totalCount} items
                </Typography> */}
                <Pagination
                    color='primary'
                    size='large'
                    count={totalPages}
                    page={pageNumber}
                    onChange={(e, page) => handlePageChange(page)}
                />
          </Box>  
        </>
    );
};

export default AppPagination;