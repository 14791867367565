import { Backdrop, Box, CircularProgress, Paper, Typography } from "@mui/material";

interface Props {
    message?: string;
}

const LoadingComponent = ({message = 'Loading...'}: Props) => {
    return (
        <Backdrop sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={true} 
            invisible={true}
        >
            <Box component={Paper} display='flex' justifyContent='center' alignItems='center' height='100vh' width={"100%"}>
                <CircularProgress size={100} color="secondary" />
                <img src="/banner.png" alt="Banner AccuFinTech" width='279px' height='75px' style={{margin:5}}/>
                <Typography variant='h4' sx={{justifyContent:'center', position:'fixed', top: '65%'}}>{message}</Typography>

            </Box>
        </Backdrop>
    );
};

export default LoadingComponent;

// {/* <img src="/banner.png" alt="Banner AccuFinTech" width='250px' height='70px' style={{justifyContent:'center', position:'fixed', top: '30%'}}/>     
//     <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
//         <CircularProgress size={100} color="secondary" />
//         <Typography variant='h4' sx={{justifyContent:'center', position:'fixed', top: '60%'}}>{message}</Typography>

//     </Box> */}