import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import DraftsIcon from '@mui/icons-material/Drafts';
import { NavLink } from "react-router-dom";
import { CenterFocusStrong, PrivacyTip, Reviews, Telegram, Terminal } from '@mui/icons-material';

const Footer = () => {
    return (
        <>
            <Box display='flex' justifyContent='center' sx={{ p: 2, mt:5, mb:0, borderTop:3, borderBottom:0, borderTopColor:'GrayText'}} width={'100%'} >
            <Divider />
                {/* <Typography variant='h5'>
                <List>
                    <ListItem
                        component={NavLink}
                        to='/login'
                        sx={{}}
                    >
                    </ListItem>
                </List>
                </Typography> */}
        <nav aria-label="main mailbox folders">
        <List>
        <ListItem disablePadding
          component={NavLink}
          to={'/privacy'}
          sx={{color: 'inherit'}}
        >
            <ListItemButton>
              <ListItemIcon>
                <PrivacyTip />
              </ListItemIcon>
              <ListItemText primary="Privacy Policy" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Terminal />
              </ListItemIcon>
              <ListItemText primary="Terms and Conditions" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CenterFocusStrong />
              </ListItemIcon>
              <ListItemText primary="Resource Center" />
            </ListItemButton>
          </ListItem>
          
        </List>
      </nav>
      <Divider orientation='vertical' variant="middle" flexItem />
      <nav aria-label="secondary mailbox folders">
        <List>
        <ListItem disablePadding
        component={NavLink}
        to={'/contact'}
        sx={{color: 'inherit'}}
        >
            <ListItemButton>
              <ListItemIcon>
                <Telegram />
              </ListItemIcon>
              <ListItemText primary="Contact Us" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary="FAQs" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Reviews />
              </ListItemIcon>
              <ListItemText primary="Testimonials" />
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary="Testimonials" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Legal Pages" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary="Testimonials" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="#simple-list">
              <ListItemText primary="Spam" />
            </ListItemButton>
          </ListItem> */}
        </List>
      </nav>
            </Box>
            
        </>
    );
};

export default Footer;