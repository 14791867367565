import { Container, Divider, Paper, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

const ServerError = () => {
    const {state} = useLocation();

    return (
        <Container component={Paper}>
            {state?.error ? (
                <>
                    <Typography gutterBottom variant='h5'>
                        {state.error.title}
                    </Typography> 
                    <Divider />
                    <Typography variant="body1" sx={{color: 'inherit', 
                            textDecoration:'none',
                            typography:'h6', whiteSpace:'balance'}}>
                        Internal server issues , please contact with our &nbsp;
                        <Typography component={NavLink}
                            to='/contact'
                            sx={{color: 'blue', 
                            textDecoration:'none',
                            typography:'h4', whiteSpace:'balance'}}
                        >
                             admins...
                        </Typography>
                        {/* <Link component="button" to="/contact"> admins...</Link> */}
                        {/* <List>
                            <ListItem
                            component={NavLink}
                            to='/contact'
                            key='/contact'
                            >
                            admins...
                            </ListItem>
                        </List> */}
                        
                    </Typography>
                </>
            ):(
                <Typography gutterBottom variant='h5'>Server error</Typography>
            )}
            
        </Container>
    );
};

export default ServerError;