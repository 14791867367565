import { Box, Button, Container, Divider, Grid, Paper, Typography } from "@mui/material";
import BasketSummary from './BasketSummary';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../app/store/configureStore';
import BasketTable from './BasketTable';

export default function BasketPage() {
    const { basket } = useAppSelector(state => state.basket);
    const itemIndex = basket?.items.findIndex(i => i.price > 0)
    // if (!basket) return <Typography variant="h3">Your basket is empty</Typography>
    if (itemIndex) {
        return (
        <Container component={Paper} sx={{width:'95%',height:300, borderRadius:'65px'}}>
            <Typography variant="h3" sx={{p:3, mt:'10%', textAlign:'center', height:'20vh'}}>Your cart is empty</Typography>
            <Divider />
            <Button fullWidth component={Link} to='/catalog'>Go back to Shop</Button>
            
        </Container>
            
        )
    } else {
        return (
            <Box sx={{pl:3, pr:3}}>
                <BasketTable items={basket.items} />
                <Grid container>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                        <BasketSummary />
                        <Button
                            component={Link}
                            to={'/checkout'}
                            variant='contained'
                            size='large'
                            fullWidth>
                            Checkout
                        </Button>
                    </Grid>
                </Grid>
            </Box>
    
        )
    }

    
}